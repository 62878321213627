@import 'scss/mixins';
@import 'scss/typography';
@import 'scss/media-queries';

.helpcenter {
  :global(.l-main__content) {
    max-width: none;
    padding: 0px;
    margin: 0px;
  }
}

.header {
  height: auto;
  min-height: 245px;
  padding: var(--space-16) 0;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @include until-tablet {
    min-height: 160px;
  }

  @include until-phone {
    padding: var(--space-10) var(--space-4);
  }
}

.headerBg {
  width: 100%;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @include until-wide-screen {
    height: 100%;
    width: auto;
  }
}

.searchHolder {
  width: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: var(--space-6);
  align-items: center;

  @include until-tablet {
    gap: var(--space-4);
  }
}

.searchTitle {
  @include header-2;
  text-align: center;

  @include until-tablet {
    @include header-3;
  }
}

.vaHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-6);
  margin: var(--space-8) 0px;

  @include until-tablet {
    flex-direction: column;
    gap: var(--space-4);
  }
}

.vaCta,
.needHelpTitle {
  @include header-4;
  font-weight: 700;
}

.faq,
.category {
  display: flex;
  flex-direction: column;

  @include maxContentWidth;
  margin: var(--space-16) auto;

  @include until-phone {
    padding: 0px var(--space-4);
    margin: var(--space-14) auto;
  }
}

.faq {
  margin-top: var(--space-10);

  @include until-phone {
    margin-top: var(--space-8);
  }
}

.faqTitle,
.categoryTitle {
  @include header-3;
  margin-bottom: var(--space-6);

  @include until-tablet {
    @include header-4;
    margin-bottom: var(--space-4);
  }
}

.needHelp {
  display: flex;
  gap: var(--space-6);
  align-items: center;
  justify-content: center;

  background-color: var(--neutral-7);
  padding: var(--space-6) 0px;
  margin-bottom: var(--space-17);

  .orText {
    text-transform: lowercase;
    @include header-4-bold;
  }

  @include until-tablet {
    padding: var(--space-6) var(--space-4);
    flex-direction: column;
    gap: var(--space-4);
    margin-bottom: var(--space-14);

    > button {
      width: 100%;
    }
  }
}

.categoryList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: var(--space-6) var(--space-10);

  @include until-tablet {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--space-5);
  }

  @include until-phone {
    display: flex;
    flex-direction: column;
    gap: var(--space-4);
  }
}

.categoryItem {
  cursor: pointer;
  width: auto;
  height: auto;
  display: flex;
  gap: var(--space-4);
  padding: var(--space-6) var(--space-4);
  border: 1px solid var(--neutral-4);
  border-radius: var(--border-radius-lg);
  align-items: flex-start;
  transition: all 0.3s ease-out;

  svg {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &:hover {
    border-color: var(--neutral-2);
    box-shadow: var(--shadow-bottom-2);
  }

  @include until-tablet {
    svg {
      flex: 0 0 32px;
    }
  }
}

.categoryContent {
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
  span {
    color: var(--neutral-1);
  }
}

.faqList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--space-8);
  border: 0px solid transparent;
  overflow: hidden;

  @include until-tablet {
    grid-template-columns: none;
    border-top-left-radius: var(--border-radius-sm);
    border-bottom-left-radius: var(--border-radius-sm);
    margin: 0px var(--space-2);
  }

  a {
    padding: var(--space-4) var(--space-7);
    background-color: var(--neutral-8);
    border-left: 4px solid var(--neutral-4);
    box-sizing: border-box;
    @include label-md;
    cursor: pointer;
    transition: all 0.2s ease-out;

    @include from-tablet {
      &:first-child,
      &:nth-child(2) {
        border-top-left-radius: var(--border-radius-sm);
      }
      &:last-child,
      &:nth-last-child(2) {
        border-bottom-left-radius: var(--border-radius-sm);
      }
    }

    @include until-tablet {
      @include label-sm;
      padding: var(--space-4) var(--space-6);
    }

    &:hover {
      transition: all 0.2s ease-out;
      background-color: var(--neutral-4);
    }
  }
}

.searchBar {
  display: flex;
  gap: var(--space-3);
  background-color: var(--white);
  border: 1px solid var(--neutral-3);
  border-radius: 50px;
  height: 48px;
  width: 360px;
  padding: 0 var(--space-4);

  &:focus-within {
    border-color: var(--black);
  }

  svg {
    align-self: center;

    path {
      fill: var(--neutral-2);
    }
  }

  button {
    align-self: center;
  }

  input {
    @include label-md;
    flex: 1;
  }

  @include until-phone {
    width: 100%;
  }
}

.searchBarContainer {
  display: flex;
  align-items: center;
  gap: var(--space-6);

  .orText {
    text-transform: lowercase;
    @include header-4-bold;
  }

  @include until-phone {
    flex-direction: column;
    width: 100%;
    gap: var(--space-4);
    padding: 0 var(--space-5);

    .orText {
      @include header-5-bold;
    }
  }
}
